<template>
  <ag-modal-container
    title="Utilisateurs supprimées"
    width="900px"
    @close="back"
  >
    <div
      v-if="!loadingData && !userList.length"
      class="text-center title mt-5"
    >
      Aucun élément
    </div>

    <user-list
      v-else
      :value="userList"
      :selected.sync="selectedUserList"
      :loading="loadingData"
      light
    />

    <template v-slot:footer>
      <v-spacer />
      <ag-confirm-dialog
        title="Confirmation"
        :message="restoreMessage"
        cancel-label="Annuler"
        confirm-label="Restaurer"
        validation-btn-color="accent"
        @confirm="restoreSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRestore"
            :disabled="!selectedUserList.length || loadingRestore"
            color="accent"
            class="ml-5"
            v-bind="attrs"
            v-on="on"
            v-text="'Restaurer'"
          />
        </template>
      </ag-confirm-dialog>
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import UserList from '../components/UserList'

export default {
  name: 'UserRestoreContainer',
  components: {
    AgModalContainer,
    AgConfirmDialog,
    UserList,
  },
  data () {
    return {
      userList: [],
      selectedUserList: [],
      loadingData: false,
      loadingRestore: false,
    }
  },
  computed: {
    restoreMessage () {
      const count = this.selectedUserList.length
      return `Souhaitez-vous restaurer ${count} utilisateur(s)`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'UserRestore') {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async fetchData () {
      this.loadingData = true
      this.userList = await Repositories.user.getList({ archived: true })
      this.loadingData = false
    },
    async restoreSelected () {
      try {
        this.loadingRestore = true
        const count = this.selectedUserList.length
        await Repositories.user.restore(
          this.selectedUserList.map(select => select._id),
        )

        this.selectedUserList = []
        this.fetchData()

        this.showSuccessNotification(`Vous avez restoré ${count} utilisateur(s)`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRestore = false
      }
    },
  },
}
</script>
